import { createGlobalStyle } from "styled-components";
import ghostShadowWoff2 from "../fonts/Ghost Shadow/GhostShadow.woff2";
import ghostShadowWoff from "../fonts/Ghost Shadow/GhostShadow.woff";
import ghostShadowTtf from "../fonts/Ghost Shadow/GhostShadow.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: "Ghost Shadow";
        src: url(${ghostShadowWoff2}) format("woff2"),
            url(${ghostShadowWoff}) format("woff"),
            url(${ghostShadowTtf}) format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    * {
        padding: 0;
        margin: 0;
        outline: none;
        box-sizing: border-box;
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background-color: #000;
    }

    a {
        color: white;
        text-decoration: none;
    }

    li {
        list-style: none;
    }
`;
