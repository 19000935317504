import { useState } from "react";
import ImageListItem from "@mui/material/ImageListItem";

import { getSizeURL } from "helpers/getSizeURL";
import PostModal from "components/PostModal";

const GridItem = ({ images, title, description, tags, handleLoad }) => {
    const [open, setOpen] = useState(false);
    const img = getSizeURL(images[0], 200);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ImageListItem
                sx={{
                    cursor: "pointer",
                    ":after": {
                        display: "block",
                        position: "absolute",
                        inset: 0,
                        transition: "all 0.2s",
                        content: '""',
                    },
                    ":hover:after": {
                        background: "rgb(210 0 0 / 50%)",
                    },
                }}
                onClick={handleOpen}
            >
                <img
                    src={img}
                    srcSet={img}
                    alt={title}
                    loading="lazy"
                    onLoad={handleLoad}
                />
            </ImageListItem>
            <PostModal
                images={images}
                title={title}
                description={description}
                tags={tags}
                open={open}
                handleClose={handleClose}
            />
        </>
    );
};

export default GridItem;
