import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import allReducers from "./reducers/index";
import { loadState } from "./localStorage";

const persistedState = loadState();
const middlewares = [thunk];

export const store = createStore(
    allReducers,
    persistedState,
    composeWithDevTools(applyMiddleware(...middlewares))
);
