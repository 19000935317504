import { Link } from "react-router-dom";
import { Stack } from "@mui/material";

import Logo from "components/Logo";
import SearchBar from "components/SearchBar";

const DesktopNav = ({ links = [], ...props } = {}) => {
    return (
        <Stack
            position="relative"
            zIndex={10}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
            color="white"
            {...props}
        >
            <Stack direction="row" alignItems="center" spacing={4}>
                <Logo size={70} />
                <SearchBar />
            </Stack>
            <Stack
                direction="row"
                spacing={3}
                sx={{ textTransform: "uppercase" }}
            >
                {links.map(({ label, to }, i) => (
                    <Link key={i} to={to}>
                        {label}
                    </Link>
                ))}
            </Stack>
        </Stack>
    );
};

export default DesktopNav;
