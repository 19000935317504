import { useLayoutEffect, useState } from "react";

const useScrollDistance = ({
    maxValue = 100,
    direction = "vertical",
    type = "percentage",
} = {}) => {
    const [distance, setDistance] = useState(0);

    useLayoutEffect(() => {
        const getHorizontalScrollPercentage = (el) => {
            const p = el.parentNode;
            const maxPixels =
                type === "percentage"
                    ? ((p.scrollWidth - p.clientWidth) / 100) * maxValue
                    : maxValue;
            return ((el.scrollLeft || p.scrollLeft) / maxPixels) * 100;
        };

        const getVerticalScrollPercentage = (el) => {
            const p = el.parentNode;
            const maxPixels =
                type === "percentage"
                    ? ((p.scrollHeight - p.clientHeight) / 100) * maxValue
                    : maxValue;
            return ((el.scrollTop || p.scrollTop) / maxPixels) * 100;
        };

        const handleScroll = () => {
            const scrollPercentage =
                direction === "vertical"
                    ? getVerticalScrollPercentage(document.body)
                    : getHorizontalScrollPercentage(document.body);
            setDistance(scrollPercentage > 100 ? 100 : scrollPercentage);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
            handleScroll();
        };
    }, [maxValue, direction, type]);

    return distance;
};

export default useScrollDistance;
