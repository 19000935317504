import { Box } from "@mui/material";

const AbsoluteBox = ({
    left = 0,
    top = 0,
    width = 1,
    height = 1,
    ...props
} = {}) => {
    return (
        <Box
            position="absolute"
            left={left}
            top={top}
            width={width}
            height={height}
            {...props}
        ></Box>
    );
};

export default AbsoluteBox;
