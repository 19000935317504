import { BrowserRouter, Routes, Route } from "react-router-dom";

import Error from "pages/Error";
import Home from "pages/Home";
import Layout from "pages/Layout";
import ScrollToTop from "utils/ScrollToTop";
import Search from "pages/Search";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="" element={<Home />} />
                    <Route path="404" element={<Error />} />
                    <Route path="busca" element={<Search />} />
                    <Route path="*" element={<Error />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
