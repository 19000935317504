import { Box } from "@mui/material";

import DesktopNav from "components/DesktopNav";
import MobileNav from "components/MobileNav";

const Nav = (props) => {
    const links = [
        { label: "Início", to: "/" },
        { label: "Ícones", to: "/busca?q=icone" },
        { label: "Banners", to: "/busca?q=banner" },
        { label: "Ilustrações", to: "/busca?q=ilustração" },
        { label: "Sites", to: "/busca?q=site" },
        { label: "Contato", to: "https://linktr.ee/byseidr" },
    ];

    return (
        <Box
            position="fixed"
            zIndex={20}
            width={1}
            px={{ xs: 2, md: 4 }}
            py={1.5}
            fontFamily="'Open Sans', sans-serif"
            {...props}
        >
            <DesktopNav display={{ xs: "none", lg: "flex" }} links={links} />
            <MobileNav display={{ xs: "flex", lg: "none" }} links={links} />
        </Box>
    );
};

export default Nav;
