import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const PostTags = ({ tags }) => {
    const handleClick = (tag) => {
        console.info(tag);
    };

    return (
        <Stack direction="row" gap="15px" flexWrap="wrap">
            {tags?.map((tag) => (
                <Chip label={`#${tag}`} onClick={() => handleClick(tag)} />
            ))}
        </Stack>
    );
};

export default PostTags;
