import useWidth from "./useWidth";

const useBreakpoint = (values) => {
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];
    const width = useWidth();
    const range = breakpoints.slice(
        0,
        breakpoints.findIndex((bp) => bp === width) + 1
    );

    for (const bp of range.reverse()) {
        if (values.hasOwnProperty(bp)) return values[bp];
        else continue;
    }
};

export default useBreakpoint;
