import { useSearchParams } from "react-router-dom";

import usePosts from "hooks/usePosts";
import Grid from "components/Grid";

const Search = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q") ?? "";
    const items = usePosts(query);

    return <Grid items={items} />;
};

export default Search;
