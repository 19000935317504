import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import useBreakpoint from "hooks/useBreakpoint";
import Gallery from "components/Gallery";
import PostTags from "components/PostTags";

const Post = ({ images, title, description, tags }) => {
    const width = useBreakpoint({ xs: "100%", md: 300 });

    return (
        <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            {images?.length && (
                <Gallery
                    width={width}
                    height="auto"
                    items={images}
                    showNav={false}
                    showPlayButton={false}
                />
            )}
            <Stack spacing={5}>
                <Typography variant="h4" component="h1">
                    {title}
                </Typography>
                {description && (
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {tags && <PostTags tags={tags} />}
            </Stack>
        </Stack>
    );
};

export default Post;
