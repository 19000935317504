import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./App.css";
import AppRoutes from "routes/AppRoutes";
import GlobalStyles from "./styles/global";

function App() {
    const theme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <AppRoutes />
        </ThemeProvider>
    );
}

export default App;
