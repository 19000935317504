import { IconButton } from "@mui/material";

import footerIcon from "styles/footerIcon";

const SocialButton = ({ color, size, Icon, ...props }) => {
    return (
        <IconButton sx={footerIcon(color, size)} {...props}>
            <Icon size={size} sx={{ fontSize: size }} />
        </IconButton>
    );
};

export default SocialButton;
