import usePosts from "hooks/usePosts";
import Grid from "components/Grid";

const Home = () => {
    const items = usePosts();

    return <Grid items={items} />;
};

export default Home;
