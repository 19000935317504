const initialState = {
    gridCount: 0,
    gridPage: 1,
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case "RESETLAYOUT":
            return initialState;
        case "SETGRIDCOUNT":
            return { ...state, gridCount: action.payload };
        case "SETGRIDPAGE":
            return { ...state, gridPage: action.payload };
        default:
            return state;
    }
};

export default layoutReducer;
