import { Box, Container, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";

import useBreakpoint from "hooks/useBreakpoint";
import useScrollThreshold from "hooks/useScrollThreshold";
import Footer from "components/Footer";
import Header from "components/Header";
import Nav from "components/Nav";

const Layout = () => {
    const threshold = useScrollThreshold({ maxValue: 50, type: "pixel" });
    const px = useBreakpoint({ xs: 0, md: 15 });
    const py = useBreakpoint({ xs: 0, md: 7 });

    return (
        <Box minHeight="100vh" px={px} py={py} bgcolor="#10100F">
            <Nav
                position={threshold ? "fixed" : "absolute"}
                my={(theme) => (threshold ? `-${theme.spacing(py)}` : 0)}
                width={(theme) => `calc(100% - 2 * ${theme.spacing(px)})`}
            />
            <Header />
            <Stack spacing={5}>
                <Container maxWidth="lg" sx={{ mt: -12 }}>
                    <Outlet />
                </Container>
                <Footer />
            </Stack>
        </Box>
    );
};

export default Layout;
