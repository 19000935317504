const footerIcon = (color, size = 15) => ({
    p: size / 15,
    bgcolor: `${color} !important`,
    color: "#000",
    border: "none",
    transition: "all 0.5s",
    ":hover": { bgcolor: "#fff !important" },
});

export default footerIcon;
