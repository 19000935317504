import { Card, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";

import Post from "components/Post";

const PostModal = ({ images, title, description, tags, open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    p: 4,
                    borderRadius: { xs: 0, md: 6 },
                    width: { xs: 1, md: "auto" },
                    height: { xs: 1, md: "auto" },
                    overflowY: "auto",
                }}
            >
                <Stack>
                    <IconButton
                        aria-label="close"
                        size="small"
                        sx={{
                            position: "absolute",
                            right: "10px",
                            top: "10px",
                            zIndex: 5,
                            bgcolor: "#616161",
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Post
                        images={images}
                        title={title}
                        description={description}
                        tags={tags}
                    />
                </Stack>
            </Card>
        </Modal>
    );
};

export default PostModal;
