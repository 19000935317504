import "react-image-gallery/styles/css/image-gallery.css";
import { styled } from "@mui/material";
import ImageGallery from "react-image-gallery";

import { cleanURL } from "helpers/cleanURL";
import { getSizeURL } from "helpers/getSizeURL";

const Gallery = ({ width, height, items, ...props }) => {
    const renderVideo = (item) => (
        <video controls style={{ width: "100%" }}>
            <source src={item.original} type="video/mp4" />
        </video>
    );

    items = items.map((item) => {
        if (typeof item === "string")
            item = { original: item, thumbnail: getSizeURL(item, 100) };
        const cleanItem = cleanURL(item?.original);
        if (cleanItem?.endsWith(".mp4"))
            item = {
                ...item,
                renderItem: renderVideo.bind(item),
                thumbnail: getSizeURL(`${cleanItem}/ik-thumbnail.jpg`, 100),
            };
        return item;
    });
    const Container = styled("div")(({ theme }) => ({
        ".image-gallery": { width, margin: "0 auto" },
        ".gallery-slide": {
            height,
            width: "100%",
            marginBottom: "8px",
            backgroundColor: "#fff",
            img: { height: "100%" },
        },
        ".image-gallery-thumbnails .image-gallery-thumbnails-container": {
            textAlign: "left",
        },
        ".image-gallery-thumbnail": {
            borderRadius: "5px",
            aspectRatio: "1/1",
            cursor: "pointer",
        },
        ".image-gallery-thumbnail.active, .image-gallery-thumbnail:focus": {
            border: `2px solid ${theme.palette.primary.main}`,
        },
        ".image-gallery-thumbnail .image-gallery-thumbnail-image": {
            aspectRatio: "1/1",
            objectFit: "contain",
        },
        ".react-thumbnail-generator img": {
            width: "100%",
            aspectRatio: "1/1",
            objectFit: "contain",
            verticalAlign: "middle",
        },
    }));

    return (
        <Container>
            <ImageGallery items={items} {...props} />
        </Container>
    );
};

export default Gallery;
