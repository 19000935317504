import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

import { db } from "../firebase";
import { simplify } from "helpers/simplify";

const usePosts = (query) => {
    const [posts, setPosts] = useState([]);
    query = simplify(query?.toLowerCase());

    useEffect(() => {
        (async () => {
            await getDocs(collection(db, "posts")).then((querySnapshot) => {
                let postDocs = querySnapshot.docs;
                postDocs = postDocs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                postDocs = query
                    ? postDocs.filter((post) => {
                          const title = simplify(post.title?.toLowerCase());
                          const tags = post.tags?.map((tag) =>
                              simplify(tag?.toLowerCase())
                          );
                          return (
                              title?.includes?.(query) ||
                              tags?.some?.((tag) => tag?.includes?.(query))
                          );
                      })
                    : postDocs;
                setPosts(postDocs);
            });
        })();
    }, [query]);

    return posts;
};

export default usePosts;
